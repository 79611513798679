<template>
  <div class="report-options flex flex-column">
    <p class="card-header-divider">Настройки сводной таблицы</p>
    <div class="report-options__body">
      <custom-select-2
        label="Группы"
        name="group_ids"
        :multiple="true"
        v-model="selectedGroups"
        :options="groupsOptions"
        :labelWidth="27"
      ></custom-select-2>
      <custom-select2
        label="Пользователи"
        :multiple="true"
        name="users"
        :labelWidth="27"
        v-model="selectedUsers"
        :settings="usersAjax"
      ></custom-select2>
      <p class="text-center mt-15 report-comment">
        В результате будут отображены все участники выбранных групп И выбранные
        пользователи
      </p>
    </div>
    <div
      v-if="selectedGroups.length || selectedUsers.length"
      class="report-options__btn flex justify-content-center"
    >
      <button @click="requestForReport" class="btn btn-accent">
        Получить таблицу
      </button>
      <button @click="requestForExcelReport" class="btn btn-accent">
        Получить excel-файл
      </button>
    </div>
    <div v-if="showExcelLink" class="redirect-link flex justify-content-center">
      Ссылка на Excel-файл:
      <a :href="showExcelLink" target="_blank">{{ showExcelLink }}</a>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";
import useAjaxSelect from "@/mixins/useAjaxSelect";
export default {
  components: { CustomSelect2 },
  name: "summary-report-parametres",
  setup(props, context) {
    const store = useStore();
    const selectedGroups = ref([]),
      selectedUsers = ref([]),
      groupsOptions = ref([]);
    const showExcelLink = ref(null);
    const groups = computed(() => store.state.group.groups);
    const { usersAjax } = useAjaxSelect();

    onBeforeMount(() => {
      store.dispatch("group/getGroups").then(() => {
        groupsOptions.value = groups.value.map((group) => {
          return { id: group.id, text: group.name };
        });
      });
    });

    const requestForReport = () => {
      if (selectedUsers.value.length > 0 || selectedGroups.value.length > 0) {
        const request = fillRequest();
        context.emit("reportRequest", request);
      } else {
        notify({
          type: "warning",
          title: "Выберите группы и/или пользователей",
        });
      }
    };

    const requestForExcelReport = () => {
      const request = fillRequest();
      store
        .dispatch("analytics/getSummaryTableExcel", request)
        .then(({ data }) => {
          window.open(`${process.env.VUE_APP_BACK}/${data}`, "Таблица");
          showExcelLink.value = `${process.env.VUE_APP_BACK}/${data}`;
        });
    };

    const fillRequest = () => {
      let request = {};
      if (selectedGroups.value.length) {
        request.groups = selectedGroups.value.map((group) => Number(group));
      }
      if (selectedUsers.value.length) {
        request.users = selectedUsers.value.map((user) => Number(user));
      }
      return request;
    };

    return {
      selectedGroups,
      groupsOptions,
      usersAjax,
      groups,
      requestForReport,
      selectedUsers,
      showExcelLink,
      requestForExcelReport,
    };
  },
};
</script>
